import type { CountryCode } from "libphonenumber-js";
import {
  AsYouType,
  getCountries,
  getCountryCallingCode,
} from "libphonenumber-js";
import type { CountryAlpha2 } from "graphql/generated/schema.platform";

const COUNTRIES = getCountries();

export const normalizePhoneNumber =
  (code?: CountryCode | CountryAlpha2) =>
  (value: string): string => {
    if (code !== undefined && !COUNTRIES.includes(code)) return value;

    let formattedValue = value;
    if (code && value.length > 0 && value.charAt(0) !== "+") {
      formattedValue = `+${getCountryCallingCode(code)}${value}`;
    }
    return new AsYouType(code).input(formattedValue);
  };
