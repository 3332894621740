/* tslint:disable */
/* eslint-disable */
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: string;
  DateTime: string;
  Decimal: number;
  JSON: unknown;
};

export type Address = {
  allInformation: Scalars['Boolean'];
  city?: Maybe<City>;
  country: Country;
  county?: Maybe<County>;
  missingAttributes?: Maybe<Array<MissingAttribute>>;
  region?: Maybe<Region>;
};

export enum AddressEditableField {
  CITY = 'CITY',
  COUNTY = 'COUNTY'
}

export enum AddressField {
  CITY = 'CITY',
  COUNTY = 'COUNTY',
  REGION = 'REGION',
  ZIPCODE = 'ZIPCODE'
}

export type Bank = {
  __typename?: 'Bank';
  accountTypes: Array<BankAccountType>;
  country: Country;
  id: Scalars['ID'];
  informationFields: BankInformationFields;
  name: Scalars['String'];
  nameAscii: Scalars['String'];
};

export type BankAccountType = {
  __typename?: 'BankAccountType';
  bank: Bank;
  id: Scalars['ID'];
  name: Scalars['String'];
  nameAscii: Scalars['String'];
};

export type BankInformationField = {
  __typename?: 'BankInformationField';
  localName?: Maybe<Scalars['String']>;
  regexValidator?: Maybe<BankInformationFieldRegexValidator>;
  required: Scalars['Boolean'];
  visible: Scalars['Boolean'];
};

export type BankInformationFieldRegexValidator = {
  __typename?: 'BankInformationFieldRegexValidator';
  errorMessage: Scalars['String'];
  regex: Scalars['String'];
};

export type BankInformationFields = {
  __typename?: 'BankInformationFields';
  accountNumber: BankInformationField;
  accountType: BankInformationField;
};

export type City = {
  __typename?: 'City';
  counties: Array<County>;
  id: Scalars['ID'];
  name: Scalars['String'];
  nameAscii: Scalars['String'];
  region: Region;
};

export type Country = {
  __typename?: 'Country';
  address2Name: Scalars['String'];
  alpha2: CountryAlpha2;
  /** ISO 3166-1 Alpha 3 code */
  alpha3: Scalars['String'];
  cityName: Scalars['String'];
  countyName: Scalars['String'];
  editableFields: Array<AddressEditableField>;
  hiddenFields: Array<AddressField>;
  id: Scalars['ID'];
  name: Scalars['String'];
  nameAscii: Scalars['String'];
  /** ISO 3166-1 numeric code */
  numeric: Scalars['Int'];
  regionName: Scalars['String'];
  regions: Array<Region>;
  requiredFields: Array<AddressField>;
  zipcodeBehaviors: Array<CountryZipcodeBehaviour>;
};

export enum CountryAlpha2 {
  AD = 'AD',
  AE = 'AE',
  AF = 'AF',
  AG = 'AG',
  AI = 'AI',
  AL = 'AL',
  AM = 'AM',
  AO = 'AO',
  AQ = 'AQ',
  AR = 'AR',
  AS = 'AS',
  AT = 'AT',
  AU = 'AU',
  AW = 'AW',
  AX = 'AX',
  AZ = 'AZ',
  BA = 'BA',
  BB = 'BB',
  BD = 'BD',
  BE = 'BE',
  BF = 'BF',
  BG = 'BG',
  BH = 'BH',
  BI = 'BI',
  BJ = 'BJ',
  BL = 'BL',
  BM = 'BM',
  BN = 'BN',
  BO = 'BO',
  BQ = 'BQ',
  BR = 'BR',
  BS = 'BS',
  BT = 'BT',
  BV = 'BV',
  BW = 'BW',
  BY = 'BY',
  BZ = 'BZ',
  CA = 'CA',
  CC = 'CC',
  CD = 'CD',
  CF = 'CF',
  CG = 'CG',
  CH = 'CH',
  CI = 'CI',
  CK = 'CK',
  CL = 'CL',
  CM = 'CM',
  CN = 'CN',
  CO = 'CO',
  CR = 'CR',
  CU = 'CU',
  CV = 'CV',
  CW = 'CW',
  CX = 'CX',
  CY = 'CY',
  CZ = 'CZ',
  DE = 'DE',
  DJ = 'DJ',
  DK = 'DK',
  DM = 'DM',
  DO = 'DO',
  DZ = 'DZ',
  EC = 'EC',
  EE = 'EE',
  EG = 'EG',
  EH = 'EH',
  ER = 'ER',
  ES = 'ES',
  ET = 'ET',
  FI = 'FI',
  FJ = 'FJ',
  FK = 'FK',
  FM = 'FM',
  FO = 'FO',
  FR = 'FR',
  GA = 'GA',
  GB = 'GB',
  GD = 'GD',
  GE = 'GE',
  GF = 'GF',
  GG = 'GG',
  GH = 'GH',
  GI = 'GI',
  GL = 'GL',
  GM = 'GM',
  GN = 'GN',
  GP = 'GP',
  GQ = 'GQ',
  GR = 'GR',
  GS = 'GS',
  GT = 'GT',
  GU = 'GU',
  GW = 'GW',
  GY = 'GY',
  HK = 'HK',
  HM = 'HM',
  HN = 'HN',
  HR = 'HR',
  HT = 'HT',
  HU = 'HU',
  ID = 'ID',
  IE = 'IE',
  IL = 'IL',
  IM = 'IM',
  IN = 'IN',
  IO = 'IO',
  IQ = 'IQ',
  IR = 'IR',
  IS = 'IS',
  IT = 'IT',
  JE = 'JE',
  JM = 'JM',
  JO = 'JO',
  JP = 'JP',
  KE = 'KE',
  KG = 'KG',
  KH = 'KH',
  KI = 'KI',
  KM = 'KM',
  KN = 'KN',
  KP = 'KP',
  KR = 'KR',
  KW = 'KW',
  KY = 'KY',
  KZ = 'KZ',
  LA = 'LA',
  LB = 'LB',
  LC = 'LC',
  LI = 'LI',
  LK = 'LK',
  LR = 'LR',
  LS = 'LS',
  LT = 'LT',
  LU = 'LU',
  LV = 'LV',
  LY = 'LY',
  MA = 'MA',
  MC = 'MC',
  MD = 'MD',
  ME = 'ME',
  MF = 'MF',
  MG = 'MG',
  MH = 'MH',
  MK = 'MK',
  ML = 'ML',
  MM = 'MM',
  MN = 'MN',
  MO = 'MO',
  MP = 'MP',
  MQ = 'MQ',
  MR = 'MR',
  MS = 'MS',
  MT = 'MT',
  MU = 'MU',
  MV = 'MV',
  MW = 'MW',
  MX = 'MX',
  MY = 'MY',
  MZ = 'MZ',
  NA = 'NA',
  NC = 'NC',
  NE = 'NE',
  NF = 'NF',
  NG = 'NG',
  NI = 'NI',
  NL = 'NL',
  NO = 'NO',
  NP = 'NP',
  NR = 'NR',
  NU = 'NU',
  NZ = 'NZ',
  OM = 'OM',
  PA = 'PA',
  PE = 'PE',
  PF = 'PF',
  PG = 'PG',
  PH = 'PH',
  PK = 'PK',
  PL = 'PL',
  PM = 'PM',
  PN = 'PN',
  PR = 'PR',
  PS = 'PS',
  PT = 'PT',
  PW = 'PW',
  PY = 'PY',
  QA = 'QA',
  RE = 'RE',
  RO = 'RO',
  RS = 'RS',
  RU = 'RU',
  RW = 'RW',
  SA = 'SA',
  SB = 'SB',
  SC = 'SC',
  SD = 'SD',
  SE = 'SE',
  SG = 'SG',
  SH = 'SH',
  SI = 'SI',
  SJ = 'SJ',
  SK = 'SK',
  SL = 'SL',
  SM = 'SM',
  SN = 'SN',
  SO = 'SO',
  SR = 'SR',
  SS = 'SS',
  ST = 'ST',
  SV = 'SV',
  SX = 'SX',
  SY = 'SY',
  SZ = 'SZ',
  TC = 'TC',
  TD = 'TD',
  TF = 'TF',
  TG = 'TG',
  TH = 'TH',
  TJ = 'TJ',
  TK = 'TK',
  TL = 'TL',
  TM = 'TM',
  TN = 'TN',
  TO = 'TO',
  TR = 'TR',
  TT = 'TT',
  TV = 'TV',
  TW = 'TW',
  TZ = 'TZ',
  UA = 'UA',
  UG = 'UG',
  UM = 'UM',
  US = 'US',
  UY = 'UY',
  UZ = 'UZ',
  VA = 'VA',
  VC = 'VC',
  VE = 'VE',
  VG = 'VG',
  VI = 'VI',
  VN = 'VN',
  VU = 'VU',
  WF = 'WF',
  WS = 'WS',
  XK = 'XK',
  YE = 'YE',
  YT = 'YT',
  ZA = 'ZA',
  ZM = 'ZM'
}

export enum CountryZipcodeBehaviour {
  BEFORE_ADDRESS = 'BEFORE_ADDRESS',
  BEFORE_LOCALITIES = 'BEFORE_LOCALITIES',
  SEARCH = 'SEARCH'
}

export type County = {
  __typename?: 'County';
  city: City;
  id: Scalars['ID'];
  name: Scalars['String'];
  nameAscii: Scalars['String'];
  zipcode?: Maybe<Scalars['String']>;
};

export type Courier = {
  __typename?: 'Courier';
  id: Scalars['ID'];
  logo?: Maybe<InlineFile>;
  name: Scalars['String'];
};

export type CourierConfig = {
  __typename?: 'CourierConfig';
  courier?: Maybe<Courier>;
  createdAt: Scalars['DateTime'];
  /** If the return label should generate automatically once the ticket is paid */
  generateLabelAutomatically: Scalars['Boolean'];
  id: Scalars['ID'];
  logo?: Maybe<InlineFile>;
  name?: Maybe<Scalars['String']>;
  platformDescriptionAgile?: Maybe<Scalars['String']>;
  platformDescriptionReturn?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

export type Currency = {
  __typename?: 'Currency';
  code: CurrencyCode;
  decimalPlaces: Scalars['Int'];
  decimalPoint: Scalars['String'];
  groupSeparator: Scalars['String'];
  prefix: Scalars['String'];
  suffix: Scalars['String'];
};

export enum CurrencyCode {
  ARS = 'ARS',
  BRL = 'BRL',
  CAD = 'CAD',
  CLP = 'CLP',
  COP = 'COP',
  EUR = 'EUR',
  GBP = 'GBP',
  MXN = 'MXN',
  PEN = 'PEN',
  USD = 'USD',
  UYU = 'UYU',
  ZAR = 'ZAR'
}

export enum DiscountTargetType {
  NEW_SHIPPING = 'NEW_SHIPPING',
  ORDER = 'ORDER',
  PRODUCTS = 'PRODUCTS'
}

export type EcommerceCatalogCollection = {
  __typename?: 'EcommerceCatalogCollection';
  id: Scalars['String'];
  name: Scalars['String'];
  searchName: Scalars['String'];
  subcollections: Array<EcommerceCatalogCollection>;
  url?: Maybe<Scalars['String']>;
};

export type EcommerceCatalogProduct = {
  __typename?: 'EcommerceCatalogProduct';
  options: Array<EcommerceCatalogProductOption>;
  url?: Maybe<Scalars['String']>;
  variants: Array<EcommerceCatalogVariant>;
};

export type EcommerceCatalogProductInformation = {
  __typename?: 'EcommerceCatalogProductInformation';
  collections: Array<Scalars['String']>;
  id: Scalars['String'];
  imageUrl?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  price?: Maybe<Money>;
  /** @deprecated Use id instead */
  productId: Scalars['String'];
  /** @deprecated Use sku instead */
  productSku?: Maybe<Scalars['String']>;
  recommendedByAlgorithm: ProductRecommendedByAlgorithm;
  sku?: Maybe<Scalars['String']>;
};

export type EcommerceCatalogProductOption = {
  __typename?: 'EcommerceCatalogProductOption';
  name: Scalars['String'];
  values: Array<Scalars['String']>;
};

export type EcommerceCatalogVariant = {
  __typename?: 'EcommerceCatalogVariant';
  hasStock: Scalars['Boolean'];
  id: Scalars['String'];
  imageUrl?: Maybe<Scalars['String']>;
  options: Array<EcommerceCatalogVariantOption>;
  price?: Maybe<Money>;
};

export type EcommerceCatalogVariantOption = {
  __typename?: 'EcommerceCatalogVariantOption';
  name: Scalars['String'];
  value: Scalars['String'];
};

export type EcommerceProduct = {
  __typename?: 'EcommerceProduct';
  backorderable: Scalars['Boolean'];
  collections: Array<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  /** Product id in ecommerce database or api */
  ecommerceId: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  /** Product type used in ecommerce. Useful to distinguish simple or complex products */
  productType?: Maybe<Scalars['String']>;
  requiresShipping: Scalars['Boolean'];
  seller?: Maybe<Scalars['String']>;
  /** Product SKU in ecommerce database or api */
  sku?: Maybe<Scalars['String']>;
  tags: Array<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  vendor?: Maybe<Scalars['String']>;
};

export type EcommerceProductVariant = {
  __typename?: 'EcommerceProductVariant';
  barcode?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  /** Variant id in ecommerce database or api */
  ecommerceId: Scalars['String'];
  fullPrice: Money;
  /** Height in centimeters */
  height?: Maybe<Scalars['Float']>;
  id: Scalars['ID'];
  imageUrl?: Maybe<Scalars['String']>;
  /** Length in centimeters */
  length?: Maybe<Scalars['Float']>;
  listPrice: Money;
  options: Array<EcommerceProductVariantOption>;
  product: EcommerceProduct;
  sku?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  /** Volumetric weight in grams */
  volumetricWeight?: Maybe<Scalars['Float']>;
  /** Real weight in grams */
  weight?: Maybe<Scalars['Float']>;
  /** Width in centimeters */
  width?: Maybe<Scalars['Float']>;
};

export type EcommerceProductVariantOption = {
  __typename?: 'EcommerceProductVariantOption';
  name: Scalars['String'];
  value: Scalars['String'];
};

export type Error = {
  __typename?: 'Error';
  field: Scalars['String'];
  messages: Array<Scalars['String']>;
};

export type GQLFile = {
  __typename?: 'File';
  id: Scalars['ID'];
  mimeType: MimeType;
  name: Scalars['String'];
  nameAscii: Scalars['String'];
  url: Scalars['String'];
};

export type FileRequest = {
  __typename?: 'FileRequest';
  fields: Scalars['JSON'];
  url: Scalars['String'];
};

export type FileStageFile = {
  __typename?: 'FileStageFile';
  fileUrl: Scalars['String'];
  request: FileRequest;
};

export type FileStageInput = {
  accelerate: Scalars['Boolean'];
  filenames: Array<Scalars['String']>;
};

export type FileStagePayload = {
  __typename?: 'FileStagePayload';
  errors?: Maybe<Array<Error>>;
  files?: Maybe<Array<FileStageFile>>;
};

export enum Gateway {
  DEUNA = 'DEUNA',
  DLOCAL = 'DLOCAL',
  KHIPU = 'KHIPU',
  MERCADO_PAGO = 'MERCADO_PAGO',
  PAGO_FACIL = 'PAGO_FACIL',
  PAYPAL = 'PAYPAL',
  SHOPIFY = 'SHOPIFY',
  STRIPE = 'STRIPE'
}

export type GiftValidation = {
  __typename?: 'GiftValidation';
  /** Number of attempts that the user has tried */
  attempts: Scalars['Int'];
  attemptsLeft: Scalars['Int'];
  id: Scalars['ID'];
  questions: Array<GiftValidationQuestion>;
};

export type GiftValidationCreateInput = {
  captcha: Scalars['String'];
  language: Language;
  orderName: Scalars['String'];
};

export type GiftValidationCreatePayload = {
  __typename?: 'GiftValidationCreatePayload';
  errors?: Maybe<Array<Error>>;
  giftValidation?: Maybe<GiftValidation>;
};

export type GiftValidationQuestion = {
  __typename?: 'GiftValidationQuestion';
  options: Array<Scalars['String']>;
  questionType: GiftValidationQuestionType;
};

export enum GiftValidationQuestionType {
  FIRST_NAME = 'FIRST_NAME',
  LAST_NAME = 'LAST_NAME',
  REGION_NAME = 'REGION_NAME'
}

export type GiftValidationValidateAnswerInput = {
  answer: Scalars['String'];
  questionType: GiftValidationQuestionType;
};

export type GiftValidationValidateInput = {
  answers: Array<GiftValidationValidateAnswerInput>;
  id: Scalars['ID'];
  language: Language;
};

export type GiftValidationValidatePayload = {
  __typename?: 'GiftValidationValidatePayload';
  errors?: Maybe<Array<Error>>;
  giftValidation?: Maybe<GiftValidation>;
  /** If success, it will return the order hash if it has closed tickets */
  orderHash?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
  /** If success, it will return the ticket request if the order doesn't have closed tickets */
  ticketRequest?: Maybe<TicketRequest>;
};

export type HasMissingAttributes = {
  allInformation: Scalars['Boolean'];
  missingAttributes?: Maybe<Array<MissingAttribute>>;
};

export enum IdentificationType {
  ARG_DNI = 'ARG_DNI',
  BRA_CPF = 'BRA_CPF',
  CHL_RUT = 'CHL_RUT',
  COL_CC = 'COL_CC',
  COL_NIT = 'COL_NIT',
  ECU_DNI = 'ECU_DNI',
  ECU_RUC = 'ECU_RUC',
  MXN_RFC = 'MXN_RFC',
  PASSPORT = 'PASSPORT',
  PER_DNI = 'PER_DNI',
  PER_RUC = 'PER_RUC',
  UYU_DNI = 'UYU_DNI'
}

export type InlineFile = {
  __typename?: 'InlineFile';
  name: Scalars['String'];
  url: Scalars['String'];
};

export type Invoice = {
  __typename?: 'Invoice';
  createdAt: Scalars['DateTime'];
  creationReason: Scalars['String'];
  currency: Currency;
  expirationDate?: Maybe<Scalars['DateTime']>;
  gateway?: Maybe<Gateway>;
  gatewayCancelStatus?: Maybe<Scalars['String']>;
  gatewayId?: Maybe<Scalars['String']>;
  gatewayPaymentStatus?: Maybe<Scalars['String']>;
  gatewayStatus?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  /** Amount paid because of shipping the new products */
  newProductsShippingAmount?: Maybe<Money>;
  paymentStatus: InvoicePaymentStatus;
  /** Amount paid because of difference in products price */
  productsDifferenceAmount: Money;
  receiptId?: Maybe<Scalars['String']>;
  receiptUrl?: Maybe<Scalars['String']>;
  /** Amount paid because of return label price */
  returnLabelAmount: Money;
  ticket?: Maybe<Ticket>;
  totalAmount: Money;
  totalLogisticsAmount: Money;
  updatedAt: Scalars['DateTime'];
  url?: Maybe<Scalars['String']>;
};

export enum InvoicePaymentStatus {
  CANCELLED = 'CANCELLED',
  CHARGED_BACK = 'CHARGED_BACK',
  EXPIRED = 'EXPIRED',
  NOT_PAID = 'NOT_PAID',
  NOT_REQUIRED = 'NOT_REQUIRED',
  PAID = 'PAID',
  PARTIALLY_PAID = 'PARTIALLY_PAID',
  PARTIALLY_REFUNDED = 'PARTIALLY_REFUNDED',
  PENDING = 'PENDING',
  REFUNDED = 'REFUNDED',
  REJECTED = 'REJECTED'
}

export enum Language {
  EN = 'EN',
  ES = 'ES',
  PT = 'PT'
}

export type MailingAddress = {
  allInformation: Scalars['Boolean'];
  city?: Maybe<City>;
  country: Country;
  county?: Maybe<County>;
  missingAttributes?: Maybe<Array<MissingAttribute>>;
  region?: Maybe<Region>;
};

export enum MimeType {
  APPLICATION_PDF = 'APPLICATION_PDF',
  IMAGE_AVIF = 'IMAGE_AVIF',
  IMAGE_JPEG = 'IMAGE_JPEG',
  IMAGE_PNG = 'IMAGE_PNG',
  IMAGE_WEBP = 'IMAGE_WEBP',
  OTHER = 'OTHER'
}

export type MissingAttribute = {
  __typename?: 'MissingAttribute';
  attribute: Scalars['String'];
  errors: Array<Scalars['String']>;
};

export type Money = {
  __typename?: 'Money';
  amount: Scalars['Float'];
  currency: Currency;
  formattedAmount: Scalars['String'];
};


export type MoneyFormattedAmountArgs = {
  accountingFormat: InputMaybe<Scalars['Boolean']>;
  includeCurrencyCode: InputMaybe<Scalars['Boolean']>;
  negate: InputMaybe<Scalars['Boolean']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  fileStage: FileStagePayload;
  giftValidationCreate: GiftValidationCreatePayload;
  giftValidationValidate: GiftValidationValidatePayload;
  platformLogin: PlatformLoginPayload;
  returnLabelUpdate: ReturnLabelUpdatePayload;
  surveySessionAnswer: SurveySessionAnswerPayload;
  ticketCancel: TicketCancelPayload;
  ticketInvoiceCreateOrGetInvoiceUrl: TicketInvoiceCreateOrGetInvoiceUrlPayload;
  ticketRequestClose: TicketRequestClosePayload;
  ticketRequestCreate: TicketRequestCreatePayload;
  ticketRequestNewProductCreate: TicketRequestNewProductCreatePayload;
  ticketRequestNewProductDelete: TicketRequestNewProductDeletePayload;
  ticketRequestReturnLabelQuoteSelect: TicketRequestReturnLabelQuoteSelectPayload;
  ticketRequestReturnProductCreate: TicketRequestReturnProductCreatePayload;
  ticketRequestReturnProductDelete: TicketRequestReturnProductDeletePayload;
  ticketRequestShippingMethodSelect: TicketRequestShippingMethodSelectPayload;
  ticketRequestUpdate: TicketRequestUpdatePayload;
};


export type MutationFileStageArgs = {
  input: FileStageInput;
};


export type MutationGiftValidationCreateArgs = {
  input: GiftValidationCreateInput;
};


export type MutationGiftValidationValidateArgs = {
  input: GiftValidationValidateInput;
};


export type MutationPlatformLoginArgs = {
  input: PlatformLoginInput;
};


export type MutationReturnLabelUpdateArgs = {
  input: ReturnLabelUpdateInput;
};


export type MutationSurveySessionAnswerArgs = {
  input: SurveySessionAnswerInput;
};


export type MutationTicketCancelArgs = {
  input: TicketCancelInput;
};


export type MutationTicketInvoiceCreateOrGetInvoiceUrlArgs = {
  input: TicketInvoiceCreateOrGetInvoiceUrlInput;
};


export type MutationTicketRequestCloseArgs = {
  input: TicketRequestCloseInput;
};


export type MutationTicketRequestCreateArgs = {
  input: TicketRequestCreateInput;
};


export type MutationTicketRequestNewProductCreateArgs = {
  input: TicketRequestNewProductCreateInput;
};


export type MutationTicketRequestNewProductDeleteArgs = {
  input: TicketRequestNewProductDeleteInput;
};


export type MutationTicketRequestReturnLabelQuoteSelectArgs = {
  input: TicketRequestReturnLabelQuoteSelectInput;
};


export type MutationTicketRequestReturnProductCreateArgs = {
  input: TicketRequestReturnProductCreateInput;
};


export type MutationTicketRequestReturnProductDeleteArgs = {
  input: TicketRequestReturnProductDeleteInput;
};


export type MutationTicketRequestShippingMethodSelectArgs = {
  input: TicketRequestShippingMethodSelectInput;
};


export type MutationTicketRequestUpdateArgs = {
  input: TicketRequestUpdateInput;
};

export type Order = {
  __typename?: 'Order';
  createdAt: Scalars['DateTime'];
  currency: Currency;
  /** Saves the cart id of the order. Useful on prestashop */
  ecommerceCartId?: Maybe<Scalars['String']>;
  ecommerceCreatedAt?: Maybe<Scalars['DateTime']>;
  /** Order id in ecommerce database or api */
  ecommerceId: Scalars['String'];
  ecommerceUrl?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  fulfillmentStatus?: Maybe<OrderFulfillmentStatus>;
  id: Scalars['ID'];
  /** Order name or reference */
  name: Scalars['String'];
  payments: Array<OrderPayment>;
  products: Array<OrderProduct>;
  /** Order alternative id in ecommerce */
  sequence?: Maybe<Scalars['String']>;
  /** amount of the total_price_shipping_discounted that is already refunded */
  shipmentAlreadyRefundedAmount: Money;
  tickets: Array<Ticket>;
  totalPaidAmount: Money;
  /** original price of shipping */
  totalPriceShipping: Money;
  totalPriceShippingDiscounted: Money;
  /** discounts in shipping */
  totalPriceShippingDiscounts: Money;
  updatedAt: Scalars['DateTime'];
};

export enum OrderFulfillmentStatus {
  CANCELLED = 'CANCELLED',
  FULFILLED = 'FULFILLED',
  PARTIAL = 'PARTIAL',
  PENDING = 'PENDING'
}

export type OrderPayment = {
  __typename?: 'OrderPayment';
  amount: Money;
  /** Payment code (authorization code or gift card number) */
  code?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  /** Name or id of the gateway in the ecommerce */
  ecommerceGatewayName: Scalars['String'];
  /** Payment id in ecommerce database or api */
  ecommerceId: Scalars['String'];
  ecommerceKind?: Maybe<Scalars['String']>;
  /** Method as named by ecommerce */
  ecommerceMethod?: Maybe<Scalars['String']>;
  ecommerceStatus?: Maybe<Scalars['String']>;
  /** Shop's account id in gateway */
  gatewayAccountId?: Maybe<Scalars['String']>;
  gatewayName: Scalars['String'];
  gatewayResponse?: Maybe<Scalars['JSON']>;
  id: Scalars['ID'];
  /** Payment identifier (last 4 digits or gift card name) */
  identifier?: Maybe<Scalars['String']>;
  installments?: Maybe<Scalars['Int']>;
  method?: Maybe<OrderPaymentMethod>;
  paidAt?: Maybe<Scalars['DateTime']>;
  /** Id of the transaction on the payment gateway */
  paymentId?: Maybe<Scalars['String']>;
  /** Payment type as defined by the gateway */
  paymentType?: Maybe<Scalars['String']>;
  /** Payment provider (card brand if method is card) */
  provider?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  /** Url of the payment */
  url?: Maybe<Scalars['String']>;
};

export enum OrderPaymentMethod {
  CASH_ON_DELIVERY = 'CASH_ON_DELIVERY',
  CREDIT_CARD = 'CREDIT_CARD',
  DEBIT_CARD = 'DEBIT_CARD',
  DEPOSIT = 'DEPOSIT',
  GIFT_CARD = 'GIFT_CARD'
}

export type OrderProduct = {
  __typename?: 'OrderProduct';
  alreadyReturned: Scalars['Boolean'];
  barcode?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  /** Discounts on product because of discount applied to all products in order */
  discountsOrder: Money;
  /** Discounts on product because of discount applied only to this product */
  discountsProduct: Money;
  ecommerceProduct?: Maybe<EcommerceProduct>;
  ecommerceProductVariant?: Maybe<EcommerceProductVariant>;
  /** Listed price of products without any discount applied */
  fullPrice: Money;
  id: Scalars['ID'];
  imageUrl?: Maybe<Scalars['String']>;
  /** Listed price of products with catalog discounts only */
  listPrice: Money;
  name: Scalars['String'];
  options: Array<OrderProductOption>;
  price: Money;
  /** Product id in ecommerce database or api */
  productId?: Maybe<Scalars['String']>;
  /** Product SKU in eccomerce database or api */
  productSku?: Maybe<Scalars['String']>;
  sku?: Maybe<Scalars['String']>;
  totalDiscounts: Money;
  updatedAt: Scalars['DateTime'];
  /** Variant id in ecommerce database or api */
  variantId?: Maybe<Scalars['String']>;
  variantName: Scalars['String'];
  variantValues: Scalars['String'];
};

export type OrderProductOption = {
  __typename?: 'OrderProductOption';
  name: Scalars['String'];
  value: Scalars['String'];
};

export type PaymentGatewayConfig = {
  __typename?: 'PaymentGatewayConfig';
  description?: Maybe<Scalars['String']>;
  hasPaymentGateway: Scalars['Boolean'];
  id: Scalars['ID'];
  instructions?: Maybe<Scalars['String']>;
  logo?: Maybe<InlineFile>;
  title?: Maybe<Scalars['String']>;
};

export enum PhoneNumberFormat {
  E164 = 'E164',
  INTERNATIONAL = 'INTERNATIONAL',
  NATIONAL = 'NATIONAL',
  RFC3966 = 'RFC3966'
}

export type PlatformLoginInput = {
  password: Scalars['String'];
};

export type PlatformLoginPayload = {
  __typename?: 'PlatformLoginPayload';
  errors?: Maybe<Array<Error>>;
  success: Scalars['Boolean'];
};

export enum PlatformViewSelectedIn {
  PRODUCTS_BY_COLLECTION = 'PRODUCTS_BY_COLLECTION',
  PRODUCT_VIEW = 'PRODUCT_VIEW',
  RECOMMENDED_PRODUCTS = 'RECOMMENDED_PRODUCTS',
  SEARCH_RESULTS = 'SEARCH_RESULTS'
}

export enum ProductRecommendedByAlgorithm {
  ALSO_BOUGHT_PRODUCTS = 'ALSO_BOUGHT_PRODUCTS',
  MOST_SELLED_PRODUCTS = 'MOST_SELLED_PRODUCTS',
  PRODUCTS_BY_COLLECTION = 'PRODUCTS_BY_COLLECTION',
  RANDOM = 'RANDOM',
  SAME_PRODUCT = 'SAME_PRODUCT',
  SEARCH_PRODUCTS_BY_NAME = 'SEARCH_PRODUCTS_BY_NAME'
}

export type QueryRoot = {
  __typename?: 'QueryRoot';
  banks: Array<Bank>;
  country: Country;
  giftValidation: GiftValidation;
  orderFromHash?: Maybe<Order>;
  returnLabel: ReturnLabel;
  returnLabelPickupDates: ReturnLabelPickupDates;
  surveySession: SurveySession;
  ticket: Ticket;
  ticketAvailablePaymentGatewayConfigs: Array<PaymentGatewayConfig>;
  ticketRequest: TicketRequest;
  ticketRequestEcommerceCatalogCollections: Array<EcommerceCatalogCollection>;
  ticketRequestEcommerceCatalogProduct?: Maybe<EcommerceCatalogProduct>;
  ticketRequestEcommerceCatalogSearchProducts: Array<EcommerceCatalogProductInformation>;
  ticketRequestEcommerceCatalogSearchProductsByCollection: Array<EcommerceCatalogProductInformation>;
  ticketRequestEcommerceCatalogSuggestProducts: Array<EcommerceCatalogProductInformation>;
  ticketRequestEcommerceCatalogSuggestProductsSimilar: Array<EcommerceCatalogProductInformation>;
  ticketRequestReturnLabelQuotes: TicketRequestReturnLabelQuotes;
  ticketRequestShippingMethods: TicketRequestShippingMethods;
  zipcodes: Array<Zipcode>;
};


export type QueryRootBanksArgs = {
  countryId: Scalars['ID'];
};


export type QueryRootCountryArgs = {
  id: Scalars['ID'];
};


export type QueryRootGiftValidationArgs = {
  id: Scalars['ID'];
};


export type QueryRootOrderFromHashArgs = {
  hash: Scalars['String'];
};


export type QueryRootReturnLabelArgs = {
  id: Scalars['ID'];
};


export type QueryRootReturnLabelPickupDatesArgs = {
  id: Scalars['ID'];
};


export type QueryRootSurveySessionArgs = {
  id: Scalars['ID'];
  token: Scalars['String'];
};


export type QueryRootTicketArgs = {
  id: Scalars['ID'];
};


export type QueryRootTicketAvailablePaymentGatewayConfigsArgs = {
  id: Scalars['ID'];
};


export type QueryRootTicketRequestArgs = {
  id: Scalars['ID'];
};


export type QueryRootTicketRequestEcommerceCatalogCollectionsArgs = {
  ticketRequestId: Scalars['ID'];
};


export type QueryRootTicketRequestEcommerceCatalogProductArgs = {
  productId: Scalars['String'];
  productSku?: InputMaybe<Scalars['String']>;
  ticketRequestId: Scalars['ID'];
};


export type QueryRootTicketRequestEcommerceCatalogSearchProductsArgs = {
  nameLike: Scalars['String'];
  ticketRequestId: Scalars['ID'];
};


export type QueryRootTicketRequestEcommerceCatalogSearchProductsByCollectionArgs = {
  collectionSearchName: Scalars['String'];
  ticketRequestId: Scalars['ID'];
};


export type QueryRootTicketRequestEcommerceCatalogSuggestProductsArgs = {
  ticketRequestId: Scalars['ID'];
};


export type QueryRootTicketRequestEcommerceCatalogSuggestProductsSimilarArgs = {
  productId: Scalars['String'];
  ticketRequestId: Scalars['ID'];
};


export type QueryRootTicketRequestReturnLabelQuotesArgs = {
  ticketRequestId: Scalars['ID'];
};


export type QueryRootTicketRequestShippingMethodsArgs = {
  ticketRequestId: Scalars['ID'];
};


export type QueryRootZipcodesArgs = {
  code: Scalars['String'];
  countryId: Scalars['ID'];
};

export type Question = {
  __typename?: 'Question';
  description: Scalars['String'];
  options: Array<Scalars['String']>;
  questionType: QuestionType;
  required: Scalars['Boolean'];
  title: Scalars['String'];
};

export type QuestionAnswer = {
  __typename?: 'QuestionAnswer';
  answer: QuestionAnswerSimpleQuestionAnswerMultiple;
  formattedAnswer: Scalars['String'];
  isMultiAnswer: Scalars['Boolean'];
  question?: Maybe<Question>;
  questionOptions: Array<Scalars['String']>;
  questionTitle: Scalars['String'];
  questionType: QuestionType;
};

export type QuestionAnswerInput = {
  answer: Scalars['String'];
  questionId: Scalars['ID'];
};

export type QuestionAnswerMultiple = {
  __typename?: 'QuestionAnswerMultiple';
  values: Array<Scalars['String']>;
};

export type QuestionAnswerSimple = {
  __typename?: 'QuestionAnswerSimple';
  value: Scalars['String'];
};

export type QuestionAnswerSimpleQuestionAnswerMultiple = QuestionAnswerMultiple | QuestionAnswerSimple;

export enum QuestionType {
  BOOLEAN = 'BOOLEAN',
  DROPDOWN = 'DROPDOWN',
  INPUT_LONG = 'INPUT_LONG',
  INPUT_SHORT = 'INPUT_SHORT',
  SELECT_MULTIPLE = 'SELECT_MULTIPLE',
  SELECT_ONE = 'SELECT_ONE'
}

export enum RefundType {
  GIFT_CARD = 'GIFT_CARD',
  ORIGINAL_PAYMENT = 'ORIGINAL_PAYMENT'
}

export type RefundTypeConfig = {
  __typename?: 'RefundTypeConfig';
  /** Description showed on platform */
  description: Scalars['String'];
  fields: Array<RefundTypeConfigField>;
  id: Scalars['ID'];
  /** Name showed on platform */
  name: Scalars['String'];
  questions: Array<RefundTypeConfigQuestion>;
  refundType: RefundType;
};

export type RefundTypeConfigField = {
  __typename?: 'RefundTypeConfigField';
  fieldType: RefundTypeConfigFieldType;
  required: Scalars['Boolean'];
};

export enum RefundTypeConfigFieldType {
  BANK_INFORMATION = 'BANK_INFORMATION',
  CUSTOMER_IDENTIFICATION = 'CUSTOMER_IDENTIFICATION',
  CUSTOMER_NAME = 'CUSTOMER_NAME'
}

export type RefundTypeConfigQuestion = {
  __typename?: 'RefundTypeConfigQuestion';
  enabled: Scalars['Boolean'];
  id: Scalars['ID'];
  question: Question;
};

export type Region = {
  __typename?: 'Region';
  /** ISO 3166-1 Alpha 2 code */
  alpha2: Scalars['String'];
  cities: Array<City>;
  counties: Array<County>;
  country: Country;
  id: Scalars['ID'];
  /** Local code used by country */
  localCode?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  nameAscii: Scalars['String'];
  /** Shopify region's code: https://shopify.dev/docs/api/admin-rest/2024-10/resources/province */
  shopifyCode: Scalars['String'];
};

export type ReturnLabel = {
  __typename?: 'ReturnLabel';
  authorizationCode?: Maybe<Scalars['String']>;
  courier?: Maybe<Courier>;
  courierConfig: CourierConfig;
  createdAt: Scalars['DateTime'];
  currency: Currency;
  deliveredAt?: Maybe<Scalars['DateTime']>;
  destination: ReturnLabelAddress;
  expirationDate?: Maybe<Scalars['DateTime']>;
  generated: Scalars['Boolean'];
  generatedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  instructions?: Maybe<Scalars['String']>;
  isCancelled: Scalars['Boolean'];
  isDelivered: Scalars['Boolean'];
  isPickupDateEditable: Scalars['Boolean'];
  isReceived: Scalars['Boolean'];
  labelUrl?: Maybe<Scalars['String']>;
  multiCourierTrackingCode?: Maybe<Scalars['String']>;
  origin: ReturnLabelAddress;
  pickupDate?: Maybe<Scalars['Date']>;
  pickupDateEditableUntil?: Maybe<Scalars['DateTime']>;
  pickupTrackingCode?: Maybe<Scalars['String']>;
  quote: ReturnLabelQuote;
  receivedByCourierAt?: Maybe<Scalars['DateTime']>;
  ticket?: Maybe<Ticket>;
  trackingCode?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

export type ReturnLabelAddress = Address & HasMissingAttributes & MailingAddress & {
  __typename?: 'ReturnLabelAddress';
  additionalInformation?: Maybe<Scalars['String']>;
  address1?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  allInformation: Scalars['Boolean'];
  city?: Maybe<City>;
  cityName?: Maybe<Scalars['String']>;
  company?: Maybe<Scalars['String']>;
  country: Country;
  county?: Maybe<County>;
  countyName?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  formatted?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  lastName?: Maybe<Scalars['String']>;
  latitude?: Maybe<Scalars['Decimal']>;
  longitude?: Maybe<Scalars['Decimal']>;
  missingAttributes?: Maybe<Array<MissingAttribute>>;
  phoneNumber?: Maybe<Scalars['String']>;
  region?: Maybe<Region>;
  updatedAt: Scalars['DateTime'];
  zipcode?: Maybe<Scalars['String']>;
};


export type ReturnLabelAddressFormattedArgs = {
  includeAddress2?: Scalars['Boolean'];
  validate?: Scalars['Boolean'];
};


export type ReturnLabelAddressPhoneNumberArgs = {
  format?: InputMaybe<PhoneNumberFormat>;
};

export type ReturnLabelPickupDates = {
  __typename?: 'ReturnLabelPickupDates';
  pickupDates: Array<Scalars['Date']>;
  returnLabel: ReturnLabel;
};

export type ReturnLabelQuote = {
  __typename?: 'ReturnLabelQuote';
  courier?: Maybe<Courier>;
  courierConfig: CourierConfig;
  createdAt: Scalars['DateTime'];
  currency: Currency;
  id: Scalars['ID'];
  includesNewShipping: Scalars['Boolean'];
  price: Money;
  updatedAt: Scalars['DateTime'];
};

export type ReturnLabelUpdateInput = {
  id: Scalars['ID'];
  pickupDate: Scalars['Date'];
};

export type ReturnLabelUpdatePayload = {
  __typename?: 'ReturnLabelUpdatePayload';
  errors?: Maybe<Array<Error>>;
  returnLabel?: Maybe<ReturnLabel>;
};

export type ReturnReason = {
  __typename?: 'ReturnReason';
  cancellation?: Maybe<Scalars['Boolean']>;
  createdAt: Scalars['DateTime'];
  enabled: Scalars['Boolean'];
  id: Scalars['ID'];
  /** the name of the reason when the product was returned */
  reason: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  warranty: Scalars['Boolean'];
};

export type Survey = {
  __typename?: 'Survey';
  commentName: Scalars['String'];
  commentRequired: Scalars['Boolean'];
  id: Scalars['ID'];
  npsName: Scalars['String'];
};

export type SurveySession = {
  __typename?: 'SurveySession';
  commentAnswer?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  /** Rendered introduction to show on platform */
  introduction: Scalars['String'];
  isAnswered: Scalars['Boolean'];
  npsAnswer?: Maybe<Scalars['Int']>;
  survey: Survey;
};

export type SurveySessionAnswerInput = {
  commentAnswer?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  npsAnswer: Scalars['Int'];
  token: Scalars['String'];
};

export type SurveySessionAnswerPayload = {
  __typename?: 'SurveySessionAnswerPayload';
  errors?: Maybe<Array<Error>>;
  surveySession?: Maybe<SurveySession>;
};

export type Ticket = {
  __typename?: 'Ticket';
  activeReturnLabel?: Maybe<ReturnLabel>;
  amounts: TicketAmounts;
  approvalStatus: TicketApprovalStatus;
  authorizationStatus: TicketAuthorizationStatus;
  /** Datetime to automatically cancel the ticket if it's not paid */
  autoCancelAt?: Maybe<Scalars['DateTime']>;
  /** Datetime to automatically rejects the ticket if it's received by the courier */
  autoRejectAt?: Maybe<Scalars['DateTime']>;
  cancellationDetails?: Maybe<TicketCancellationDetails>;
  /** @deprecated Use newProductsCount */
  countNewProducts: Scalars['Int'];
  /** @deprecated Use returnProductsCount */
  countReturnProducts: Scalars['Int'];
  courier?: Maybe<Courier>;
  courierConfig?: Maybe<CourierConfig>;
  createdAt: Scalars['DateTime'];
  creditNoteStatus?: Maybe<TicketCreditNoteStatus>;
  currency: Currency;
  customer?: Maybe<TicketCustomer>;
  /** @deprecated use 'newShippingPrice' */
  estimatedNewOrderShipping?: Maybe<Money>;
  forcePayNewShipping: Scalars['Boolean'];
  forcePayReturnLabel: Scalars['Boolean'];
  hasPayableInvoice: Scalars['Boolean'];
  id: Scalars['ID'];
  invoice?: Maybe<Invoice>;
  /** Null if not edited */
  invoiceEditedAt?: Maybe<Scalars['DateTime']>;
  isApproved: Scalars['Boolean'];
  isAuthorized: Scalars['Boolean'];
  isCancellable: Scalars['Boolean'];
  isCancelled: Scalars['Boolean'];
  isExchange: Scalars['Boolean'];
  isInvoiceEdited: Scalars['Boolean'];
  isPaid: Scalars['Boolean'];
  isPayable: Scalars['Boolean'];
  isReturn: Scalars['Boolean'];
  isWarranty: Scalars['Boolean'];
  language: Language;
  meetsAutoCancel: Scalars['Boolean'];
  meetsAutoReject: Scalars['Boolean'];
  newOrder?: Maybe<Order>;
  newProducts: Array<TicketNewProduct>;
  newProductsCount: Scalars['Int'];
  /** New products total amount */
  newProductsTotalAmount: Money;
  /** Shipping price of new products in exchanges when the ticket is created, before any payment edition or approval */
  newShippingPrice?: Maybe<Money>;
  paidAt?: Maybe<Scalars['DateTime']>;
  paymentStatus?: Maybe<InvoicePaymentStatus>;
  /** @deprecated Use 'createdAt' */
  platformFinishedAt: Scalars['DateTime'];
  reference?: Maybe<Scalars['String']>;
  refundInformation?: Maybe<TicketRefundInformation>;
  /** Original shipment refunded amount when the ticket is created, before the approval */
  refundOriginalShipmentAmount: Money;
  refundStatus?: Maybe<TicketRefundStatus>;
  restockStatus?: Maybe<TicketRestockStatus>;
  returnAddress?: Maybe<TicketReturnAddress>;
  /** Return label price when the ticket is created, before any payment edition or approval */
  returnLabelPrice: Money;
  returnLabelQuote?: Maybe<ReturnLabelQuote>;
  returnOrder: Order;
  returnOrderProducts: Array<TicketReturnOrderProduct>;
  returnProducts: Array<TicketReturnProduct>;
  returnProductsCount: Scalars['Int'];
  /** Return products total amount */
  returnProductsTotalAmount: Money;
  returnTrackingStatus: TicketReturnTrackingStatus;
  rma: Scalars['Int'];
  shippingMethod?: Maybe<TicketShippingMethod>;
  summary: TicketSummary;
  ticketType: TicketType;
  /** @deprecated Use newProductsTotalAmount */
  totalNewProductsAmount: Money;
  /** @deprecated Use totalProductsDifferenceAmount */
  totalProductsDifference: Money;
  /** Difference in products ('new_products_total_amount' - 'return_products_total_amount') */
  totalProductsDifferenceAmount: Money;
  /** @deprecated Use returnProductsTotalAmount */
  totalReturnProductsAmount: Money;
  totalToPay: Money;
  totalToRefund: Money;
  updatedAt: Scalars['DateTime'];
};

export type TicketAmountDetails = {
  __typename?: 'TicketAmountDetails';
  fromRefund: Money;
  isNull: Scalars['Boolean'];
  toPay: Money;
  total: Money;
};

export type TicketAmounts = {
  __typename?: 'TicketAmounts';
  newProducts: Money;
  newShipping: TicketAmountDetails;
  productsDifference: Money;
  refundOriginalShipment: Money;
  returnLabel: TicketAmountDetails;
  returnProducts: Money;
  toPay: Money;
  toRefund: Money;
};

export enum TicketApprovalStatus {
  APPROVED = 'APPROVED',
  AUTO = 'AUTO',
  CANCELLED = 'CANCELLED',
  PENDING = 'PENDING',
  REJECTED = 'REJECTED'
}

export enum TicketAuthorizationStatus {
  AUTHORIZED = 'AUTHORIZED',
  AUTO = 'AUTO',
  PARTIALLY_AUTHORIZED = 'PARTIALLY_AUTHORIZED',
  PENDING = 'PENDING',
  REJECTED = 'REJECTED'
}

export type TicketCancelInput = {
  additionalInformation: Scalars['String'];
  id: Scalars['ID'];
};

export type TicketCancelPayload = {
  __typename?: 'TicketCancelPayload';
  cancellationDetails?: Maybe<TicketCancellationDetails>;
  errors?: Maybe<Array<Error>>;
  ticket?: Maybe<Ticket>;
};

export type TicketCancellationDetails = {
  __typename?: 'TicketCancellationDetails';
  additionalInformation: Scalars['String'];
  cancelledAt: Scalars['DateTime'];
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  notifyCustomer: Scalars['Boolean'];
  originator: TicketCancellationOriginator;
  updatedAt: Scalars['DateTime'];
};

export enum TicketCancellationOriginator {
  API = 'API',
  AUTO = 'AUTO',
  DASHBOARD = 'DASHBOARD',
  PLATFORM = 'PLATFORM'
}

export enum TicketCreditNoteStatus {
  CANCELLED = 'CANCELLED',
  EMITTED = 'EMITTED',
  NOT_EMITTED = 'NOT_EMITTED',
  REJECTED = 'REJECTED'
}

export type TicketCustomer = HasMissingAttributes & {
  __typename?: 'TicketCustomer';
  allInformation: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  /** Identification of the customer given a type */
  identification?: Maybe<Scalars['String']>;
  identificationType?: Maybe<IdentificationType>;
  lastName?: Maybe<Scalars['String']>;
  missingAttributes?: Maybe<Array<MissingAttribute>>;
  phoneNumber?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};


export type TicketCustomerPhoneNumberArgs = {
  format?: InputMaybe<PhoneNumberFormat>;
};

export type TicketDiscount = {
  __typename?: 'TicketDiscount';
  fixed: Money;
  id: Scalars['ID'];
  name: Scalars['String'];
  order: Scalars['Int'];
  percentage: Scalars['Float'];
};

export type TicketInvoiceCreateOrGetInvoiceUrlInput = {
  paymentGatewayConfigId?: InputMaybe<Scalars['ID']>;
  ticketId: Scalars['ID'];
};

export type TicketInvoiceCreateOrGetInvoiceUrlPayload = {
  __typename?: 'TicketInvoiceCreateOrGetInvoiceUrlPayload';
  errors?: Maybe<Array<Error>>;
  url?: Maybe<Scalars['String']>;
};

export type TicketNewProduct = {
  __typename?: 'TicketNewProduct';
  barcode?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  discounts: Array<TicketNewProductDiscount>;
  /** Listed price of products without any discount applied */
  fullPrice: Money;
  id: Scalars['ID'];
  imageUrl?: Maybe<Scalars['String']>;
  /** Listed price of products with catalog discounts only */
  listPrice: Money;
  matchedReturnProduct?: Maybe<TicketReturnProduct>;
  name: Scalars['String'];
  options: Array<TicketNewProductOption>;
  platformViewSelectedIn: PlatformViewSelectedIn;
  price: Money;
  /** Product id in ecommerce database or api */
  productId: Scalars['String'];
  productSku?: Maybe<Scalars['String']>;
  recommendedByAlgorithm: ProductRecommendedByAlgorithm;
  sku?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  /** Variant id in ecommerce database or api */
  variantId?: Maybe<Scalars['String']>;
  variantName: Scalars['String'];
  variantValues: Scalars['String'];
};

export type TicketNewProductDiscount = {
  __typename?: 'TicketNewProductDiscount';
  discount: TicketDiscount;
  /** Amount discounted from the base price */
  discountAmount: Money;
  id: Scalars['ID'];
};

export type TicketNewProductOption = {
  __typename?: 'TicketNewProductOption';
  name: Scalars['String'];
  value: Scalars['String'];
};

export type TicketRefundInformation = {
  __typename?: 'TicketRefundInformation';
  bank?: Maybe<Bank>;
  bankAccountNumber?: Maybe<Scalars['String']>;
  bankAccountType?: Maybe<BankAccountType>;
  createdAt: Scalars['DateTime'];
  firstName?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  /** Identification of the customer given a type */
  identification?: Maybe<Scalars['String']>;
  identificationType?: Maybe<IdentificationType>;
  lastName?: Maybe<Scalars['String']>;
  questionAnswers: Array<TicketRefundInformationQuestionAnswer>;
  refundTypeConfig: RefundTypeConfig;
  updatedAt: Scalars['DateTime'];
};

export type TicketRefundInformationQuestionAnswer = {
  __typename?: 'TicketRefundInformationQuestionAnswer';
  answer: QuestionAnswer;
  id: Scalars['ID'];
  refundTypeConfigQuestion?: Maybe<RefundTypeConfigQuestion>;
};

export enum TicketRefundStatus {
  CANCELLED = 'CANCELLED',
  IN_PROGRESS = 'IN_PROGRESS',
  NOT_NEEDED = 'NOT_NEEDED',
  NOT_REFUNDED = 'NOT_REFUNDED',
  REFUNDED = 'REFUNDED',
  REJECTED = 'REJECTED'
}

export type TicketRequest = {
  __typename?: 'TicketRequest';
  acceptCancellations: Scalars['Boolean'];
  acceptExchanges: Scalars['Boolean'];
  acceptReturns: Scalars['Boolean'];
  allowDownselling: Scalars['Boolean'];
  allowMixedCancellations: Scalars['Boolean'];
  allowUpselling: Scalars['Boolean'];
  amounts: TicketAmounts;
  availableRefundTypeConfigs: Array<RefundTypeConfig>;
  courier?: Maybe<Courier>;
  courierConfig?: Maybe<CourierConfig>;
  createdAt: Scalars['DateTime'];
  currency: Currency;
  customer?: Maybe<TicketRequestCustomer>;
  discounts: Array<TicketRequestDiscount>;
  /** If the shipping methods are able to be fetched or we have to wait */
  fetchableShippingMethods: Scalars['Boolean'];
  forcePayNewShipping: Scalars['Boolean'];
  forcePayReturnLabel: Scalars['Boolean'];
  id: Scalars['ID'];
  inputStatus: TicketRequestInputStatus;
  isCancellation: Scalars['Boolean'];
  isClosed: Scalars['Boolean'];
  isExchange: Scalars['Boolean'];
  isFullCancellation: Scalars['Boolean'];
  isReturnAddressEditable: Scalars['Boolean'];
  isWarranty: Scalars['Boolean'];
  language: Language;
  newProducts: Array<TicketRequestNewProduct>;
  newProductsCount: Scalars['Int'];
  newProductsTotalAmount: Money;
  newShippingPrice?: Maybe<Money>;
  quotesUpToDate: Scalars['Boolean'];
  refundInformation?: Maybe<TicketRequestRefundInformation>;
  refundOriginalShipmentAmount: Money;
  requiresReturnAddress?: Maybe<Scalars['Boolean']>;
  requiresReverseDelivery?: Maybe<Scalars['Boolean']>;
  returnAddress?: Maybe<TicketRequestReturnAddress>;
  returnLabelPrice?: Maybe<Money>;
  returnLabelQuote?: Maybe<TicketRequestReturnLabelQuote>;
  returnOrder: Order;
  returnOrderProducts: Array<TicketRequestReturnOrderProduct>;
  returnProducts: Array<TicketRequestReturnProduct>;
  returnProductsCount: Scalars['Int'];
  returnProductsTotalAmount: Money;
  shippingMethod?: Maybe<TicketRequestShippingMethod>;
  shippingMethodUpToDate: Scalars['Boolean'];
  ticket?: Maybe<Ticket>;
  ticketType: TicketRequestType;
  totalProductsDifferenceAmount: Money;
  totalToPay: Money;
  totalToRefund: Money;
  updatedAt: Scalars['DateTime'];
};

export type TicketRequestCloseInput = {
  id: Scalars['ID'];
};

export type TicketRequestClosePayload = {
  __typename?: 'TicketRequestClosePayload';
  errors?: Maybe<Array<Error>>;
  ticketRequest?: Maybe<TicketRequest>;
};

export type TicketRequestCreateInput = {
  forceCreate: Scalars['Boolean'];
  fromGift: Scalars['Boolean'];
  language: Language;
  orderName: Scalars['String'];
  startValidations?: InputMaybe<Array<TicketRequestCreateStartValidationInput>>;
  validation?: InputMaybe<Scalars['String']>;
};

export type TicketRequestCreatePayload = {
  __typename?: 'TicketRequestCreatePayload';
  errors?: Maybe<Array<Error>>;
  orderHash?: Maybe<Scalars['String']>;
  ticketRequest?: Maybe<TicketRequest>;
};

export type TicketRequestCreateStartValidationInput = {
  acceptedAt?: InputMaybe<Scalars['DateTime']>;
  id: Scalars['ID'];
};

export type TicketRequestCustomer = HasMissingAttributes & {
  __typename?: 'TicketRequestCustomer';
  allInformation: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  /** Identification of the customer given a type */
  identification?: Maybe<Scalars['String']>;
  identificationType?: Maybe<IdentificationType>;
  lastName?: Maybe<Scalars['String']>;
  missingAttributes?: Maybe<Array<MissingAttribute>>;
  phoneNumber?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};


export type TicketRequestCustomerPhoneNumberArgs = {
  format?: InputMaybe<PhoneNumberFormat>;
};

export type TicketRequestCustomerUpsertInput = {
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  identification?: InputMaybe<Scalars['String']>;
  identificationType?: InputMaybe<IdentificationType>;
  lastName?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
};

export type TicketRequestDiscount = {
  __typename?: 'TicketRequestDiscount';
  fixed: Money;
  name: Scalars['String'];
  percentage: Scalars['Float'];
  targetType: DiscountTargetType;
};

export enum TicketRequestInputStatus {
  ADDRESS = 'ADDRESS',
  CLOSED = 'CLOSED',
  RESOLUTION = 'RESOLUTION',
  RETURN = 'RETURN',
  SUMMARY = 'SUMMARY'
}

export type TicketRequestNewProduct = {
  __typename?: 'TicketRequestNewProduct';
  barcode?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  discountAmount?: Maybe<Money>;
  discountedPrice?: Maybe<Money>;
  discounts: Array<TicketRequestDiscount>;
  /** Listed price of products without any discount applied */
  fullPrice: Money;
  id: Scalars['ID'];
  imageUrl?: Maybe<Scalars['String']>;
  /** Listed price of products with catalog discounts only */
  listPrice: Money;
  matchedReturnProduct?: Maybe<TicketRequestReturnProduct>;
  name: Scalars['String'];
  options: Array<TicketRequestNewProductOption>;
  platformViewSelectedIn: PlatformViewSelectedIn;
  price: Money;
  /** Product id in ecommerce database or api */
  productId: Scalars['String'];
  productSku?: Maybe<Scalars['String']>;
  recommendedByAlgorithm: ProductRecommendedByAlgorithm;
  sku?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  /** Variant id in ecommerce database or api */
  variantId?: Maybe<Scalars['String']>;
  variantName: Scalars['String'];
  variantValues: Scalars['String'];
};

export type TicketRequestNewProductCreateInput = {
  platformViewSelectedIn: PlatformViewSelectedIn;
  productId: Scalars['String'];
  productSku?: InputMaybe<Scalars['String']>;
  recommendedByAlgorithm: ProductRecommendedByAlgorithm;
  ticketRequestId: Scalars['ID'];
  variantId: Scalars['String'];
};

export type TicketRequestNewProductCreatePayload = {
  __typename?: 'TicketRequestNewProductCreatePayload';
  errors?: Maybe<Array<Error>>;
  newProduct?: Maybe<TicketRequestNewProduct>;
  ticketRequest?: Maybe<TicketRequest>;
};

export type TicketRequestNewProductDeleteInput = {
  id: Scalars['ID'];
};

export type TicketRequestNewProductDeletePayload = {
  __typename?: 'TicketRequestNewProductDeletePayload';
  errors?: Maybe<Array<Error>>;
  ticketRequest?: Maybe<TicketRequest>;
};

export type TicketRequestNewProductOption = {
  __typename?: 'TicketRequestNewProductOption';
  name: Scalars['String'];
  value: Scalars['String'];
};

export type TicketRequestRefundInformation = {
  __typename?: 'TicketRequestRefundInformation';
  bank?: Maybe<Bank>;
  bankAccountNumber?: Maybe<Scalars['String']>;
  bankAccountType?: Maybe<BankAccountType>;
  createdAt: Scalars['DateTime'];
  firstName?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  /** Identification of the customer given a type */
  identification?: Maybe<Scalars['String']>;
  identificationType?: Maybe<IdentificationType>;
  lastName?: Maybe<Scalars['String']>;
  questionAnswers: Array<TicketRequestRefundInformationQuestionAnswer>;
  refundTypeConfig: RefundTypeConfig;
  updatedAt: Scalars['DateTime'];
};

export type TicketRequestRefundInformationQuestionAnswer = {
  __typename?: 'TicketRequestRefundInformationQuestionAnswer';
  answer: QuestionAnswer;
  id: Scalars['ID'];
  refundTypeConfigQuestion?: Maybe<RefundTypeConfigQuestion>;
};

export type TicketRequestRefundInformationUpsertInput = {
  bankAccountNumber?: InputMaybe<Scalars['String']>;
  bankAccountTypeId?: InputMaybe<Scalars['ID']>;
  bankId?: InputMaybe<Scalars['ID']>;
  firstName?: InputMaybe<Scalars['String']>;
  identification?: InputMaybe<Scalars['String']>;
  identificationType?: InputMaybe<IdentificationType>;
  lastName?: InputMaybe<Scalars['String']>;
  questionAnswers?: InputMaybe<Array<QuestionAnswerInput>>;
  refundTypeConfigId: Scalars['ID'];
};

export type TicketRequestReturnAddress = Address & HasMissingAttributes & MailingAddress & {
  __typename?: 'TicketRequestReturnAddress';
  additionalInformation?: Maybe<Scalars['String']>;
  address1?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  allInformation: Scalars['Boolean'];
  city?: Maybe<City>;
  cityName?: Maybe<Scalars['String']>;
  company?: Maybe<Scalars['String']>;
  country: Country;
  county?: Maybe<County>;
  countyName?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  firstName?: Maybe<Scalars['String']>;
  formatted?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  lastName?: Maybe<Scalars['String']>;
  latitude?: Maybe<Scalars['Decimal']>;
  longitude?: Maybe<Scalars['Decimal']>;
  missingAttributes?: Maybe<Array<MissingAttribute>>;
  phoneNumber?: Maybe<Scalars['String']>;
  region?: Maybe<Region>;
  updatedAt: Scalars['DateTime'];
  zipcode?: Maybe<Scalars['String']>;
};


export type TicketRequestReturnAddressFormattedArgs = {
  includeAddress2?: Scalars['Boolean'];
  validate?: Scalars['Boolean'];
};


export type TicketRequestReturnAddressPhoneNumberArgs = {
  format?: InputMaybe<PhoneNumberFormat>;
};

export type TicketRequestReturnAddressUpsertInput = {
  additionalInformation?: InputMaybe<Scalars['String']>;
  address1?: InputMaybe<Scalars['String']>;
  address2?: InputMaybe<Scalars['String']>;
  cityId?: InputMaybe<Scalars['ID']>;
  cityName?: InputMaybe<Scalars['String']>;
  company?: InputMaybe<Scalars['String']>;
  countryId?: InputMaybe<Scalars['ID']>;
  countyId?: InputMaybe<Scalars['ID']>;
  countyName?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  regionId?: InputMaybe<Scalars['ID']>;
  zipcode?: InputMaybe<Scalars['String']>;
};

export type TicketRequestReturnLabelQuote = {
  __typename?: 'TicketRequestReturnLabelQuote';
  id: Scalars['ID'];
  platformDescription?: Maybe<Scalars['String']>;
  returnLabelQuote: ReturnLabelQuote;
};

export type TicketRequestReturnLabelQuoteSelectInput = {
  id: Scalars['ID'];
};

export type TicketRequestReturnLabelQuoteSelectPayload = {
  __typename?: 'TicketRequestReturnLabelQuoteSelectPayload';
  errors?: Maybe<Array<Error>>;
  ticketRequest?: Maybe<TicketRequest>;
  ticketRequestReturnLabelQuote?: Maybe<TicketRequestReturnLabelQuote>;
};

export type TicketRequestReturnLabelQuotes = {
  __typename?: 'TicketRequestReturnLabelQuotes';
  quotes: Array<TicketRequestReturnLabelQuote>;
  ticketRequest: TicketRequest;
};

export type TicketRequestReturnOrderProduct = {
  __typename?: 'TicketRequestReturnOrderProduct';
  id: Scalars['ID'];
  isCancellation: Scalars['Boolean'];
  isCheckeable: Scalars['Boolean'];
  notCheckeableWithNoWarrantyReason?: Maybe<Scalars['String']>;
  notCheckeableWithWarrantyReason?: Maybe<Scalars['String']>;
  notExchangeableReason?: Maybe<Scalars['String']>;
  notReturnableReason?: Maybe<Scalars['String']>;
  orderProduct: OrderProduct;
  returnProduct?: Maybe<TicketRequestReturnProduct>;
  returnReasons: Array<ReturnReason>;
};

export type TicketRequestReturnProduct = {
  __typename?: 'TicketRequestReturnProduct';
  additionalInformation: Scalars['String'];
  createdAt: Scalars['DateTime'];
  fileUrls: Array<Scalars['String']>;
  id: Scalars['ID'];
  matchedNewProduct?: Maybe<TicketRequestNewProduct>;
  orderProduct: OrderProduct;
  questionAnswers: Array<QuestionAnswer>;
  reason: TicketRequestReturnProductReason;
  updatedAt: Scalars['DateTime'];
};

export type TicketRequestReturnProductCreateInput = {
  additionalInformation?: InputMaybe<Scalars['String']>;
  fileUrls?: InputMaybe<Array<Scalars['String']>>;
  orderProductId: Scalars['ID'];
  questionAnswers?: InputMaybe<Array<QuestionAnswerInput>>;
  returnReasonId?: InputMaybe<Scalars['ID']>;
  returnReasonOther?: InputMaybe<Scalars['String']>;
  ticketRequestId: Scalars['ID'];
};

export type TicketRequestReturnProductCreatePayload = {
  __typename?: 'TicketRequestReturnProductCreatePayload';
  errors?: Maybe<Array<Error>>;
  ticketRequest?: Maybe<TicketRequest>;
};

export type TicketRequestReturnProductDeleteInput = {
  id: Scalars['ID'];
};

export type TicketRequestReturnProductDeletePayload = {
  __typename?: 'TicketRequestReturnProductDeletePayload';
  errors?: Maybe<Array<Error>>;
  ticketRequest?: Maybe<TicketRequest>;
};

export type TicketRequestReturnProductReason = {
  __typename?: 'TicketRequestReturnProductReason';
  fromList: Scalars['Boolean'];
  isCancellation?: Maybe<Scalars['Boolean']>;
  isWarranty: Scalars['Boolean'];
  returnReason?: Maybe<ReturnReason>;
  title: Scalars['String'];
};

export type TicketRequestShippingMethod = {
  __typename?: 'TicketRequestShippingMethod';
  description?: Maybe<Scalars['String']>;
  ecommerceId?: Maybe<Scalars['String']>;
  estimatedTime?: Maybe<Scalars['Float']>;
  id: Scalars['ID'];
  isPickup?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  price: Money;
  ticketRequest: TicketRequest;
};

export type TicketRequestShippingMethodSelectInput = {
  id: Scalars['ID'];
};

export type TicketRequestShippingMethodSelectPayload = {
  __typename?: 'TicketRequestShippingMethodSelectPayload';
  errors?: Maybe<Array<Error>>;
  ticketRequestShippingMethod?: Maybe<TicketRequestShippingMethod>;
};

export type TicketRequestShippingMethods = {
  __typename?: 'TicketRequestShippingMethods';
  methods: Array<TicketRequestShippingMethod>;
  ticketRequest: TicketRequest;
};

export enum TicketRequestType {
  EXCHANGE = 'EXCHANGE',
  RETURN = 'RETURN'
}

export type TicketRequestUpdateInput = {
  customer?: InputMaybe<TicketRequestCustomerUpsertInput>;
  id: Scalars['ID'];
  inputStatus?: InputMaybe<TicketRequestInputStatus>;
  language?: InputMaybe<Language>;
  refundInformation?: InputMaybe<TicketRequestRefundInformationUpsertInput>;
  returnAddress?: InputMaybe<TicketRequestReturnAddressUpsertInput>;
};

export type TicketRequestUpdatePayload = {
  __typename?: 'TicketRequestUpdatePayload';
  errors?: Maybe<Array<Error>>;
  ticketRequest?: Maybe<TicketRequest>;
};

export enum TicketRestockStatus {
  NOT_NEEDED = 'NOT_NEEDED',
  PARTIAL = 'PARTIAL',
  PENDING = 'PENDING',
  RESTOCKED = 'RESTOCKED'
}

export type TicketReturnAddress = Address & HasMissingAttributes & MailingAddress & {
  __typename?: 'TicketReturnAddress';
  additionalInformation?: Maybe<Scalars['String']>;
  address1?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  allInformation: Scalars['Boolean'];
  city?: Maybe<City>;
  cityName?: Maybe<Scalars['String']>;
  company?: Maybe<Scalars['String']>;
  country: Country;
  county?: Maybe<County>;
  countyName?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  firstName?: Maybe<Scalars['String']>;
  formatted?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  lastName?: Maybe<Scalars['String']>;
  latitude?: Maybe<Scalars['Decimal']>;
  longitude?: Maybe<Scalars['Decimal']>;
  missingAttributes?: Maybe<Array<MissingAttribute>>;
  phoneNumber?: Maybe<Scalars['String']>;
  region?: Maybe<Region>;
  updatedAt: Scalars['DateTime'];
  zipcode?: Maybe<Scalars['String']>;
};


export type TicketReturnAddressFormattedArgs = {
  includeAddress2?: Scalars['Boolean'];
  validate?: Scalars['Boolean'];
};


export type TicketReturnAddressPhoneNumberArgs = {
  format?: InputMaybe<PhoneNumberFormat>;
};

export type TicketReturnOrderProduct = {
  __typename?: 'TicketReturnOrderProduct';
  id: Scalars['ID'];
  orderProduct: OrderProduct;
  returnProduct?: Maybe<TicketReturnProduct>;
};

export type TicketReturnProduct = {
  __typename?: 'TicketReturnProduct';
  additionalInformation: Scalars['String'];
  approvalStatus?: Maybe<TicketReturnProductApprovalStatus>;
  authorizationStatus: TicketReturnProductAuthorizationStatus;
  createdAt: Scalars['DateTime'];
  files: Array<GQLFile>;
  id: Scalars['ID'];
  isApproved: Scalars['Boolean'];
  isAuthorized: Scalars['Boolean'];
  matchedNewProduct?: Maybe<TicketNewProduct>;
  orderProduct: OrderProduct;
  questionAnswers: Array<QuestionAnswer>;
  reason: TicketReturnProductReason;
  updatedAt: Scalars['DateTime'];
};

export enum TicketReturnProductApprovalStatus {
  APPROVED = 'APPROVED',
  PENDING = 'PENDING',
  REJECTED = 'REJECTED'
}

export enum TicketReturnProductAuthorizationStatus {
  AUTHORIZED = 'AUTHORIZED',
  NOT_REQUIRED = 'NOT_REQUIRED',
  PENDING = 'PENDING',
  REJECTED = 'REJECTED'
}

export type TicketReturnProductReason = {
  __typename?: 'TicketReturnProductReason';
  /** if the reason is or was different from reason_other */
  fromList: Scalars['Boolean'];
  /** the name of the reason when the product was returned */
  reason: Scalars['String'];
  returnReason?: Maybe<ReturnReason>;
  warranty: Scalars['Boolean'];
};

export enum TicketReturnTrackingStatus {
  CANCELLED = 'CANCELLED',
  DELIVERED = 'DELIVERED',
  EXPIRED = 'EXPIRED',
  FAILED = 'FAILED',
  ISSUE = 'ISSUE',
  LOST = 'LOST',
  MANUALLY_DELIVERED = 'MANUALLY_DELIVERED',
  NOT_REQUIRED = 'NOT_REQUIRED',
  NO_RETURN_LABEL = 'NO_RETURN_LABEL',
  NO_TRACKING = 'NO_TRACKING',
  ON_WAY = 'ON_WAY',
  ON_WAY_RETURN = 'ON_WAY_RETURN',
  PENDING_COLLECT = 'PENDING_COLLECT',
  PENDING_COLLECT_RETURN = 'PENDING_COLLECT_RETURN',
  PENDING_DISPATCH = 'PENDING_DISPATCH',
  RECEIVED = 'RECEIVED',
  RETURNED = 'RETURNED',
  STORAGE = 'STORAGE'
}

export type TicketShippingMethod = {
  __typename?: 'TicketShippingMethod';
  description?: Maybe<Scalars['String']>;
  ecommerceId?: Maybe<Scalars['String']>;
  estimatedTime?: Maybe<Scalars['Float']>;
  id: Scalars['ID'];
  isPickup?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  price: Money;
};

export type TicketSummary = {
  __typename?: 'TicketSummary';
  currentStep: TicketSummaryStep;
  description?: Maybe<Scalars['String']>;
  steps: Array<TicketSummaryStep>;
  title?: Maybe<Scalars['String']>;
};

export type TicketSummaryStep = {
  __typename?: 'TicketSummaryStep';
  status: TicketSummaryStepStatus;
  stepType: TicketSummaryStepType;
  title: Scalars['String'];
};

export enum TicketSummaryStepStatus {
  FULFILLED = 'FULFILLED',
  IN_PROGRESS = 'IN_PROGRESS',
  PENDING = 'PENDING',
  REJECTED = 'REJECTED'
}

export enum TicketSummaryStepType {
  APPROVAL = 'APPROVAL',
  AUTHORIZATION = 'AUTHORIZATION',
  CANCELLATION = 'CANCELLATION',
  NEW_ORDER = 'NEW_ORDER',
  PAYMENT = 'PAYMENT',
  REFUND = 'REFUND',
  RETURN_TRACKING = 'RETURN_TRACKING'
}

export enum TicketType {
  EXCHANGE = 'EXCHANGE',
  RETURN = 'RETURN'
}

export type Zipcode = {
  __typename?: 'Zipcode';
  cityName?: Maybe<Scalars['String']>;
  code: Scalars['String'];
  country: Country;
  countyName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  region: Region;
};
